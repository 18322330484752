<mat-toolbar class="center-align-container">
  <div class="font-16 flex-row-container">
    <mat-icon style="font-size: 22px !important; color: #005CB8;" (click)="navigateTo('home')" class="pointer m-r-10">
      home
    </mat-icon>
    <img src="../assets/RockefellerLogowhitBackGround.svg" alt="Reg-BI Attestation" class="m-r-10"
      style="height: 30px;"> |&nbsp;&nbsp;
    <span style="text-transform: uppercase">{{title}}</span>
  </div>
  <div class="flex-row-container">
    <mat-icon style="font-size: 22px !important;" class="pointer m-r-10" inline=true [matMenuTriggerFor]="advTreeMenu"
      *ngIf="user && (originalRole !== 'FA')" #advMenuTrigger="matMenuTrigger">
      groups
    </mat-icon>
    <span *ngIf="user" title="{{user.role}}">{{user.name}}</span>
    <mat-icon style="font-size: 18px !important;" *ngIf="showUserSel" class="pointer" [matMenuTriggerFor]="superMenu"
      inline=true>more_vert</mat-icon>
  </div>
</mat-toolbar>
<app-loader></app-loader>
<mat-menu #superMenu="matMenu" class="md-lg-font m-lr-30 p-20">
  <div class="m-tb-10" (click)="$event.stopPropagation();">

    <mat-radio-group *ngIf="showUserSel" [(ngModel)]="simRole" class="flex-row-container"
      (click)="$event.stopPropagation();">
      <mat-radio-button value="SUPER" class="p-5" (click)="$event.stopPropagation();">SUPER</mat-radio-button>
      <mat-radio-button value="SUPERVISION" class="p-5"
        (click)="$event.stopPropagation();">SUPERVISION</mat-radio-button>
      <mat-radio-button value="COMP" class="p-5" (click)="$event.stopPropagation();">COMPLIANCE</mat-radio-button>
      <mat-radio-button value="HO" class="p-5" (click)="$event.stopPropagation();">HO</mat-radio-button>
      <mat-radio-button value="BM" class="p-5" (click)="$event.stopPropagation();">DS</mat-radio-button>
      <mat-radio-button value="FA" class="p-5" (click)="$event.stopPropagation();">PA/CA</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="flex-row-container-right-align m-r-10 m-t-10 p-t-10 border-t-gray">
    <a mat-button color="Primary" class="m-r-10">Cancel</a>
    <a mat-stroked-button color="accent" class="p-10" (click)="changeUser()">Apply</a>
  </div>
</mat-menu>

<!-- Advsor Tree - menu-->
<mat-menu #advTreeMenu="matMenu" class="advTree-menu">
  <ng-template matMenuContent>
    <div class="flex-col-container m-10" (click)="$event.stopPropagation();">
      <!-- <app-advTreeFilter 
              [AdvisorsTreeData]="advTree" 
            [SelectedRepIds]="repIds"  
            (OnAppliedFilter)="advListChanged($event)"
              >
          </app-advTreeFilter> -->

      <app-advTreeFilter [advTreeData]=advTree [selectedRep]="repIds" (cancelFilter)="closeFilterMenu()"
        (onFilterChanged)="advListChanged($event)">
      </app-advTreeFilter>

    </div>
  </ng-template>
</mat-menu>

<router-outlet *ngIf="!isIframe" class="w3-animate w3-animate-right"></router-outlet>
<!-- test for auto trigger -->